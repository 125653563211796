// Blogs.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Blogs = ({ data }) => {
  const navigate = useNavigate();


 const handleClick = (item) => {
    const filteredItem = {
      ...item
    };
    navigate(`/blog/${item.id}`, { state: filteredItem });
  };
  

  return (
    <div className="w-full dark:bg-gray-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-theme-blue dark:text-white sm:text-4xl">
            Code Chronicles
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-gray-300">
            Unfolding the stories behind innovation and technology
          </p>
        </div>

        <div className="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {data.map((post) => (
            <article
              key={post.id}
              onClick={() => handleClick(post)}
              className="cursor-pointer relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 dark:bg-gray-700 px-8 py-8 pb-8"
              style={{ paddingTop: '40%' }}
            >
              <img
                src={post.image}
                alt={post.title}
                className="absolute inset-0 -z-10 h-full w-full object-cover"
              />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                <time dateTime={post.created_at} className="mr-8">
                  {new Date(post.created_at).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </time>
                <div className="-ml-4 flex items-center gap-x-1">
                  <svg
                    viewBox="0 0 2 2"
                    className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50"
                  >
                    <circle cx="1" cy="1" r="1"></circle>
                  </svg>
                  <div className="flex gap-x-2.5">
                    <img
                      src={post.authorImage}
                      alt={post.author}
                      className="h-6 w-6 flex-none rounded-full bg-white/10"
                    />
                    {post.author}
                  </div>
                </div>
              </div>

              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                {post.title}
              </h3>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
