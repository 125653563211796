import React from 'react';
import { useLocation } from 'react-router-dom';
import LikeShareview from './LikeShareview';
import Comment from './Comment';

const BlogDetail = () => {
  const location = useLocation(); 
  const { title, image, views, likes, author, content, authorImage, created_at, images, id } = location.state || {}; 

  const formatContent = (htmlContent) =>
    htmlContent
      .replace(/<a/g, `<a class="text-gray-800 hover:text-theme-purple no-underline border-b-2" style="border-color: #1731DA"`)
      .replace(/<p>/g, '<p class="py-3">')
      .replace(/<li>/g, '<li class="py-2">');

  return (
    <div>
      {/* Header */}
      <div className="text-center pt-2 md:pt-2">
        <p className="text-sm md:text-base text-theme-purple font-bold">
          <time dateTime={created_at} className="mr-1">
            {new Date(created_at).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }).replace(/\b\w+\b/, (month) => month.toUpperCase())}
          </time>
          <span className="text-gray-900">/</span> GETTING STARTED
        </p>
        <div className="mx-2 md:mx-36">
          <h1 className="break-normal text-theme-blue text-3xl md:text-4xl">{title}</h1>
        </div>
      </div>

      {/* Image Section */}
      <div
        className="container w-11/12 md:w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded"
        style={{ backgroundImage: `url(${image})`, height: '75vh' }}
      />

      {/* Post Content */}
      <div
        className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal"
        style={{ fontFamily: 'Georgia, serif' }}
      >
        <div
          className="text-2xl md:text-3xl mb-5"
          dangerouslySetInnerHTML={{ __html: formatContent(content) }}
        />

        {/* Additional Images Section */}
        {images && images.length > 0 && (
          <div>
            {images.map((img, index) => (
              <div key={index} className="mb-5">
                {img.image && <img src={img.image} alt="Blog Content" className="w-full rounded-lg mb-4" />}
                {img.content && (
                  <div dangerouslySetInnerHTML={{ __html: formatContent(img.content) }} />
                )}
                {img.code_snippet && (
                  <pre className="bg-gray-900 rounded text-white font-mono text-base p-4">
                    <code className="break-words whitespace-pre-wrap">{img.code_snippet}</code>
                  </pre>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Author Section */}
      <div className="flex w-full items-center font-sans p-8 md:p-24 md:pt-1">
        <img
          className="w-10 h-10 rounded-full mr-4"
          src={authorImage || 'http://i.pravatar.cc/300'}
          alt="Avatar of Author"
        />
        <div className="flex-1">
          <p className="text-base font-bold text-base md:text-xl leading-none">{author}</p>
          <p className="text-gray-600 text-xs md:text-base">{title}</p>
        </div>
      </div>

      {/* Like, views, and Share Section */}
      <LikeShareview blogPostId={id} initialLikes={likes} /> 

      {/* Comment Section */}
      <Comment blogPostId={id} />

    </div>
  );
};

export default BlogDetail;
