import React from "react";
import Button from "../elements/Button";
import BrandLogo from "../assets/images/scode_logo1.png";

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      <div className=" w-[100px] h-[100px]  rounded-full  relative">
        <img
          className=" w-[100px] h-[100px] object-cover absolute  top-0"
          src={BrandLogo}
          alt="Build Website logo"
        />
      </div>
    </Button>
  );
}
